import type { ReactNode, FC } from 'react';

import { TextButton } from '@xing-com/button';
import {
  Section,
  Container as LayoutPrimitivesContainer,
} from '@xing-com/layout-primitives';
import { H } from '@xing-com/typography';

import { useCtaLinkOnClick } from '../../components/cta-link';
import { ShowMore } from '../../components/show-more';
import { TimelineEntry } from '../entry';
import { EntryGrid } from '../entry-grid';
import type { ITimelineEntryProps } from '../types';

import { Container, CTA, EntriesList } from './bucket.styles';

export interface Props {
  children: ReactNode;
  headline?: string | ReactNode;
  scrollTrackingId?: string;
}

export interface IBucketProps {
  entries: Array<ITimelineEntryProps | null> | null;
  headline: ReactNode;
  trackingParamWithoutPrefix: string;
  ctaTranslationKey: string;
  sectionCtaDataQa?: string;
  entryDataQa?: string;
  scrollTrackingId?: string;
  scrambledProfileUrl: string | null;
}

export const Bucket: FC<Props> = ({ headline, children }) => {
  if (!headline && !children) {
    return null;
  }

  return (
    <Container>
      {headline && (
        <Section>
          <LayoutPrimitivesContainer>
            <H size="large" sizeWide="xlarge" as="h3">
              {headline}
            </H>
          </LayoutPrimitivesContainer>
        </Section>
      )}
      <EntriesList>{children}</EntriesList>
    </Container>
  );
};

export const BucketWithEntries: FC<IBucketProps> = ({
  entries,
  headline,
  trackingParamWithoutPrefix,
  ctaTranslationKey,
  sectionCtaDataQa,
  entryDataQa,
  scrollTrackingId,
  scrambledProfileUrl,
}) => {
  const onCtaButtonClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix,
  });

  if (!entries || !entries.length) {
    return null;
  }

  return (
    <>
      <Bucket headline={headline} scrollTrackingId={scrollTrackingId}>
        {entries.map((entry, i) => (
          <TimelineEntry key={i} entryDataQa={entryDataQa} {...entry} />
        ))}
      </Bucket>

      <CTA>
        <EntryGrid>
          <TextButton onClick={onCtaButtonClick} data-qa={sectionCtaDataQa}>
            <ShowMore translationKey={ctaTranslationKey} />
          </TextButton>
        </EntryGrid>
      </CTA>
    </>
  );
};
