import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';

import { TextButton } from '@xing-com/button';
import { Container, Section } from '@xing-com/layout-primitives';

import { useCtaLinkOnClick } from '../cta-link';
import { PageSection } from '../page-section';
import type { PageSectionProps } from '../page-section/page-section';
import { ShowMore } from '../show-more';

import { TagsList } from './list';

export interface IProps extends PageSectionProps {
  tags: Array<string>;
  highlightedTags?: Array<string>;
  headline?: string;
  ctaTranslationKey: string;
  ctaTrackingParamWithoutPrefix: string;
  tagDataQa?: string;
  scrambledProfileUrl: string | null;
}

export const TagsListPageSection: FC<IProps> = ({
  tags,
  highlightedTags,
  dataQa,
  headlineTranslationKey,
  ctaTranslationKey,
  ctaTrackingParamWithoutPrefix,
  tagDataQa,
  scrambledProfileUrl,
  scrollTrackingId,
}) => {
  const onCtaButtonClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: ctaTrackingParamWithoutPrefix,
  });

  if (isEmpty(compact(tags)) && isEmpty(compact(highlightedTags))) {
    return null;
  }

  const pageSectionAfterHeadline = (
    <TextButton onClick={onCtaButtonClick}>
      <ShowMore translationKey={ctaTranslationKey} />
    </TextButton>
  );

  return (
    <PageSection
      dataQa={dataQa}
      headlineTranslationKey={headlineTranslationKey}
      afterHeadline={pageSectionAfterHeadline}
      scrollTrackingId={scrollTrackingId}
    >
      <Section noBlockPadding>
        <Container>
          {highlightedTags && (
            <TagsList
              tags={highlightedTags}
              highlighted
              tagDataQa={`${tagDataQa}-highlighted`}
            />
          )}
          {tags && <TagsList tags={tags} tagDataQa={tagDataQa} />}
        </Container>
      </Section>
    </PageSection>
  );
};
