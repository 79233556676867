import type { FC } from 'react';
import styled from 'styled-components';

import {
  DisplayAdProfileHalfpageSlotBig,
  DisplayAdProfileHalfpageSlotSmall,
  DisplayAdProfileLeaderboardSlot,
  DisplayAdProfileRectangleSlot,
  DisplayAdsBiddingScript,
} from '@xing-com/crate-ads-display-ads';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
// eslint-disable-next-line node/no-unpublished-import
import type { ProfileLoProfileQuery } from '@xing-com/crate-profile-main-loader-logged-out';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { Container, Section } from '@xing-com/layout-primitives';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

import { AlphabetizedDirectory } from './alphabetized-directory';
import { Benefits } from './benefits';
import { CtaBannerProvider } from './components/cta-link';
import { TagsListPageSection } from './components/tags-list-page-section';
import { HeadSectionTags, StructuredData } from './helmet';
import { IsProfilePreviewPageProvider } from './helpers';
import { LanguageSkills } from './language-skills';
import { LegalNotice } from './legal-notice';
import { ProfilePreviewHeader } from './profile-preview-header';
import { SimilarProfiles } from './similar-profiles';
import { StickyButtonLayer } from './sticky-button-layer';
import { Timeline } from './timeline';
import { UserBag } from './user-bag';
import type { XingIdNonError } from './xing-id';
import { XingId } from './xing-id';

const AdsContainer = styled(Container)`
  justify-content: center;
  display: flex;
  min-height: 90px;

  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }

  section {
    display: block;
  }
`;

export type ProfileViewProps = {
  data: ProfileLoProfileQuery;
  isProfilePreviewPage: boolean;
  legalInformation?: string | null;
  pageName: string;
  xingId: XingIdNonError;
};

export const ProfileView: FC<ProfileViewProps> = ({
  data,
  isProfilePreviewPage,
  legalInformation,
  pageName,
  xingId,
}) => {
  const { isLoggedOut } = useLoginState();

  const user = xingId && {
    firstName: xingId.firstName,
    lastName: xingId.lastName,
    scrambledProfileUrl: data?.profilePageMeta?.scrambledProfileUrl || null,
  };
  const education = data?.profileEducation?.education;
  const workExperience = data?.profileWorkExperience?.collection;
  const languages =
    data.profileLanguageSkills?.__typename ===
    'LoggedOutProfileLanguageCollection'
      ? data.profileLanguageSkills.collection
      : [];
  const skills = data?.profileSkills?.skills ?? [];
  const topSkills = data?.profileSkills?.topSkills ?? [];
  const displayName = xingId?.displayName;

  return (
    <IsProfilePreviewPageProvider value={isProfilePreviewPage}>
      {isLoggedOut && (
        <>
          <DisplayAdsBiddingScript />
          <DisplayAdProfileHalfpageSlotSmall />
          <DisplayAdProfileHalfpageSlotBig />
        </>
      )}

      <CtaBannerProvider>
        {isProfilePreviewPage ? <ProfilePreviewHeader /> : null}
        <NoSSR>
          <StickyButtonLayer
            xingId={xingId}
            scrambledProfileUrl={user.scrambledProfileUrl}
          />
        </NoSSR>

        <XingId data={xingId} scrambledProfileUrl={user.scrambledProfileUrl} />

        {isLoggedOut && (
          <Section noBlockPadding>
            <AdsContainer>
              <DisplayAdProfileLeaderboardSlot />
            </AdsContainer>
          </Section>
        )}
        <UserBag data={data.peopleUserBag} xingId={xingId} />
        <TagsListPageSection
          dataQa="skills-section"
          headlineTranslationKey="PROFILE_LO_FE_SKILLS_HEADLINE"
          ctaTranslationKey="PROFILE_LO_FE_UNFENCED_CTA"
          ctaTrackingParamWithoutPrefix="upsell_cv_skills"
          highlightedTags={topSkills}
          tags={skills}
          tagDataQa="skills-tags"
          scrambledProfileUrl={user.scrambledProfileUrl}
          scrollTrackingId="SkillsModule"
        />
        <Timeline
          xingId={user}
          education={education}
          workExperience={workExperience}
        />
        {!!languages?.length && <LanguageSkills data={languages} />}

        {isLoggedOut && (
          <AdsContainer>
            <DisplayAdProfileRectangleSlot />
          </AdsContainer>
        )}
        <SimilarProfiles data={data?.similarProfiles?.collection} />
        {!!legalInformation && (
          <LegalNotice
            displayName={displayName}
            legalInformation={legalInformation}
          />
        )}
        <Benefits scrambledProfileUrl={user.scrambledProfileUrl} />
        <AlphabetizedDirectory />
        <HeadSectionTags
          pageName={pageName}
          data={data.profilePageMeta}
          forceNoindex={isProfilePreviewPage}
        />
        <StructuredData data={data?.profileStructuredData} />
      </CtaBannerProvider>
    </IsProfilePreviewPageProvider>
  );
};
