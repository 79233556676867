import type { PropsWithChildren, FC } from 'react';

import { Link } from '@xing-com/link';

import { useTrackingParamWithContext } from '../../helpers';

type TrackingLinkProps = {
  className?: string;
  href: string;
  title?: string;
  trackingParamWithoutPrefix: string;
};

export const TrackingLink: FC<PropsWithChildren<TrackingLinkProps>> = ({
  children,
  className,
  href,
  title,
  trackingParamWithoutPrefix,
}) => {
  const trackingParam = useTrackingParamWithContext(trackingParamWithoutPrefix);
  const hrefWithTracking = `${href}?sc_o=${trackingParam}`;

  return (
    <Link className={className} href={hrefWithTracking} title={title}>
      {children}
    </Link>
  );
};
