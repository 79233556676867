import { useNavigate, useLocation } from '@reach/router';
import { useCallback, useContext, useState } from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';
import { useHost } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';

import {
  useIsProfilePreviewPage,
  useTrackingParamWithContext,
} from '../../helpers';

import { CtaBannerContext } from './cta-banner-context';

type CtaLinkOptions = {
  trackingParamWithoutPrefix: string;
  scrambledProfileUrl: string | null;
};

export const getAuthorizationLink = (
  pathname: string,
  scrambledProfileUrl: string | null,
  trackingParam?: string | null
): string => {
  const url = new URL('/start/signup', 'https://placeholder.com');
  url.searchParams.append('dest_url', pathname);
  url.searchParams.append('signup_channel', 'profileapp');
  if (scrambledProfileUrl) {
    url.searchParams.append('register_redirect_to', scrambledProfileUrl);
  }
  if (trackingParam) {
    url.searchParams.append('sc_o', trackingParam);
  }

  return `${url.pathname}${url.search}`;
};

export const useCtaLinkOnClick = ({
  scrambledProfileUrl,
  trackingParamWithoutPrefix,
}: CtaLinkOptions): ((e: { preventDefault: () => void }) => void) => {
  const { redirectToLogin } = useHost();
  const { pathname } = useLocation();
  const { isRecurringUser } = useRuntimeConfig();
  const isProfilePreviewPage = useIsProfilePreviewPage();
  const triggerBanner = useContext(CtaBannerContext);
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);

  const trackingParam = useTrackingParamWithContext(trackingParamWithoutPrefix);

  const { track } = useTracking<{
    PropTrackAction: string;
  }>();

  const navigate = useNavigate();

  const authorizationLink = getAuthorizationLink(
    pathname,
    scrambledProfileUrl,
    trackingParamWithoutPrefix ? trackingParam : null
  );

  if (shouldRedirectToLogin) {
    redirectToLogin();
  }

  const onClick = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (isProfilePreviewPage) {
        track({
          type: 'interaction',
          event: 'PropTrackAction',
          PropTrackAction: trackingParam,
        });
        triggerBanner();
      } else if (isRecurringUser) {
        setShouldRedirectToLogin(true);
      } else {
        navigate(authorizationLink);
      }
    },
    [
      authorizationLink,
      isProfilePreviewPage,
      isRecurringUser,
      navigate,
      track,
      trackingParam,
      triggerBanner,
    ]
  );

  return onClick;
};
