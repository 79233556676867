// Profile Main Route
export const ROUTE_PROFILE = '/xingternal/profile';
export const getProfileRouteWithId = (
  profileId: string,
  tab = 'web_profiles'
): string => `${ROUTE_PROFILE}/${profileId}/${tab}`;

// Profile Main Route LOGGED_OUT
export const ROUTE_PROFILE_LO = '/profile';
export const getProfileRouteWithIdLo = (profileId: string): string =>
  `${ROUTE_PROFILE_LO}/${profileId}`;

// Profile-preview Route
export const ROUTE_PROFILE_PREVIEW = '/xingternal/profile-preview';

// About Me Module Routes
export const ROUTE_ABOUT_ME_EDIT = ROUTE_PROFILE + '/my_profile/about-me/edit';

// Hobbies and Interests Module Routes
export const ROUTE_EDIT_INTERESTS =
  ROUTE_PROFILE + '/my_profile/interests/edit';

// Business Card Module Routes
export const ROUTE_BC_CONTACT_DETAILS_EDIT =
  ROUTE_PROFILE + '/my_profile/xing-id/contact-details/edit';
export const ROUTE_BC_CONTACT_DETAILS_EDIT_BUSINESS =
  ROUTE_PROFILE + '/my_profile/xing-id/contact-details/edit/business';
export const ROUTE_BC_CONTACT_DETAILS_EDIT_PRIVATE =
  ROUTE_PROFILE + '/my_profile/xing-id/contact-details/edit/private';
export const ROUTE_BC_COVER_IMAGE_EDIT =
  ROUTE_PROFILE + '/my_profile/xing-id/cover-image/edit';
export const ROUTE_BC_HIRING_EDIT =
  ROUTE_PROFILE + '/my_profile/hiring-details/edit';
export const ROUTE_BC_PERSONAL_DETAILS_EDIT =
  ROUTE_PROFILE + '/my_profile/personal-details/edit';
export const ROUTE_BC_PROFILE_IMAGE_DELETE =
  ROUTE_PROFILE + '/my_profile/xing-id/profile-image/delete';
export const ROUTE_BC_PROFILE_IMAGE_EDIT =
  ROUTE_PROFILE + '/my_profile/xing-id/profile-image/edit';
export const ROUTE_BC_OCCUPATION_EDIT =
  ROUTE_PROFILE + '/my_profile/xing-id/occupations/edit';
export const ROUTE_BC_STATUS_MESSAGE_EDIT =
  ROUTE_PROFILE + '/my_profile/xing-id/status-message/edit';
export const getContactDetailsRoute = (profileId: string): string =>
  `${ROUTE_PROFILE}/${profileId}/xing-id/contact-details`;

// Skills Module Routes
export const ROUTE_SKILLS_ADD = ROUTE_PROFILE + '/my_profile/skills/add';
export const ROUTE_SKILLS_EDIT = ROUTE_PROFILE + '/my_profile/skills/edit';
export const ROUTE_SKILLS_PERFORMANCE =
  ROUTE_PROFILE + '/my_profile/skills/performance';
// for @reach/router that doesn't seem to comprehend such "big" links as the three above
export const ROUTE_SKILLS_BASEPATH = ROUTE_PROFILE + '/my_profile/skills';
export const ROUTE_SKILLS_ADD_SHORT = '/add';
export const ROUTE_SKILLS_EDIT_SHORT = '/edit';
export const ROUTE_SKILLS_PERFORMANCE_SHORT = '/performance';

// Languages Module Routes
export const ROUTE_LANGUAGES_EDIT =
  ROUTE_PROFILE + '/my_profile/languages/edit';

// Legal Notice Module Routes
export const ROUTE_LEGAL_INFORMATION_EDIT =
  ROUTE_PROFILE + '/my_profile/legal-information/edit';
export const getShowLegalInformationUrl = (profileId: string): string =>
  `${ROUTE_PROFILE}/${profileId}/legal-information/show`;

// Timeline Module Routes
export const ROUTE_TIMELINE_ADD = ROUTE_PROFILE + '/my_profile/timeline/add';
export const ROUTE_TIMELINE_ADD_EMPLOYEE =
  ROUTE_PROFILE + '/my_profile/timeline/add/employee';
export const ROUTE_TIMELINE_ADD_UNIVERSITY_STUDENT =
  ROUTE_PROFILE + '/my_profile/timeline/add/university_student';
export const ROUTE_TIMELINE_EDIT = ROUTE_PROFILE + '/my_profile/timeline/edit';
const ROUTE_TIMELINE_SHOW = ROUTE_PROFILE + '/my_profile/timeline/show';
export const getTimelineShowRoute = (profileId: string, urn: string): string =>
  `${ROUTE_TIMELINE_SHOW}/${profileId}/${urn}`;

// Visibility Settings (ModuleStore) Routes
export const ROUTE_VISIBILITY_SETTINGS =
  ROUTE_PROFILE + '/my_profile/module-store';
