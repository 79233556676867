import lowerCase from 'lodash/lowerCase';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
// eslint-disable-next-line node/no-unpublished-import
import type { ProfileLoProfileQuery } from '@xing-com/crate-profile-main-loader-logged-out';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import { type FallbackImageType } from '@xing-com/super-ellipse';
import { UserFlag } from '@xing-com/user-flag';
import { Column, Grid, Row } from '@xing-com/xing-grid';

import { useCtaLinkOnClick } from '../components/cta-link';
import { ShowMore } from '../components/show-more';

import { CoverImage } from './cover-image';
import { HCardMicrodata } from './hcard-microdata';
import { Location } from './location';
import { Occupations } from './occupations';
import { ReportProfileLink } from './report-profile-link';
import { StatusMessage } from './status-message';
import {
  CtaContainer,
  HeadlineContainer,
  MainCtaButtonContainer,
  PhotoContainer,
  PhotoRow,
  ReportLink,
  StyledButton,
  StyledContainer,
  StyledCtaLink,
  StyledSection,
  StyledSectionHeadline,
  StyledSuperEllipse,
  UserFlagContainer,
  UserNameContainer,
  UserNameContainerText,
  UserNameRow,
} from './xing-id.styles';

export type XingIdNonError = NonNullable<
  Exclude<
    ProfileLoProfileQuery['xingIdWithError'],
    { __typename: 'ResponseError' }
  >
>;

export type XingIdProps = {
  data: XingIdNonError;
  scrambledProfileUrl: string | null;
};

type displayFlagType =
  | 'PREMIUM'
  | 'BASIC'
  | 'MODERATOR'
  | 'AMBASSADOR'
  | 'INSIDER'
  | 'PRO_COACH'
  | 'PRO_TRAINER';

export const XingId: FC<XingIdProps> = ({ data, scrambledProfileUrl }) => {
  const displayName = data.displayName;
  const profileImage: string = data.profileImage?.[0]?.url || '';
  const gender = lowerCase(data.gender ?? 'neutral');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const imageType = `${gender}-user` as FallbackImageType;
  const hasDefaultHeaderImage = data.hasDefaultHeaderImage;
  const displayFlag = data.userFlags?.displayFlag ?? null;
  const headerImage = data.headerImage;
  const statusMessage = data.status?.localizationValue ?? null;
  const id = data.id;
  const realId = id.split('.')[0];
  const occupations = data.occupations ?? null;
  const location = data.location?.displayLocation ?? null;

  const { isRecurringUser } = useRuntimeConfig();
  const { locale } = useIntl();

  const onCtaButtonClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: 'upsell_id_whole_profile',
  });

  const onCtaTextClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: 'upsell_id_main_cta',
  });

  const onUpsellClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: 'upsell_id_picture',
  });

  const mainCtaText =
    isRecurringUser && locale === 'de'
      ? 'PROFILE_LO_XING_ID_MAIN_CTA_BUTTON_RECURRING_USER'
      : 'PROFILE_LO_XING_ID_MAIN_CTA_BUTTON';

  return (
    <StyledSection>
      <StyledContainer>
        {data && <HCardMicrodata xingId={data} />}
        <Grid>
          <CoverImage
            hasDefaultHeaderImage={hasDefaultHeaderImage}
            displayFlag={displayFlag}
            headerImage={headerImage}
          />
          <PhotoRow>
            <Column sizeTiny={4} size={3} sizeDesktop={3}>
              <PhotoContainer>
                <StyledCtaLink onClick={onUpsellClick}>
                  <StyledSuperEllipse
                    src={profileImage}
                    type={imageType}
                    profileName={displayName}
                    data-qa="xing-id-profile-image"
                  />
                </StyledCtaLink>
              </PhotoContainer>
            </Column>
          </PhotoRow>
          <UserNameRow>
            <Column
              size={12}
              offsetTablet={3}
              sizeTablet={9}
              offsetDesktop={3}
              sizeDesktop={9}
            >
              <UserNameContainer>
                <UserNameContainerText>
                  <HeadlineContainer>
                    <StyledSectionHeadline as="h1">
                      {displayName}
                    </StyledSectionHeadline>
                    {displayFlag && (
                      <UserFlagContainer>
                        <UserFlag
                          data-qa="xing-id-flag"
                          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                          displayFlag={displayFlag as displayFlagType}
                          size="medium"
                          showBasicFlag
                          userId={id}
                        />
                      </UserFlagContainer>
                    )}
                  </HeadlineContainer>
                  {statusMessage && <StatusMessage status={statusMessage} />}
                </UserNameContainerText>

                <MainCtaButtonContainer>
                  <StyledButton
                    variant="primary"
                    size="medium"
                    onClick={onCtaButtonClick}
                  >
                    <FormattedMessage id={mainCtaText} />
                  </StyledButton>

                  <ReportLink>
                    <ReportProfileLink id={realId} />
                  </ReportLink>
                </MainCtaButtonContainer>
              </UserNameContainer>
            </Column>
          </UserNameRow>
          {occupations && <Occupations data={occupations} />}
          {location && <Location location={location} />}
          <Row>
            <Column
              offsetDesktop={3}
              offsetTablet={3}
              size={12}
              sizeDesktop={7}
              sizeTablet={9}
            >
              <CtaContainer data-qa="xing-id-login-cta">
                <TextButton onClick={onCtaTextClick} size="medium">
                  <ShowMore
                    translationKey="PROFILE_LO_FE_XING_ID_CTA"
                    bold
                    size="large"
                  />
                </TextButton>
              </CtaContainer>
            </Column>
          </Row>
        </Grid>
      </StyledContainer>
    </StyledSection>
  );
};
