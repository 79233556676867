import type { ReactNode, FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Section,
  Container as LayoutPrimitivesContainter,
} from '@xing-com/layout-primitives';

import { SectionHeadline } from '../section-headline';

import { AfterHeadline, Container, ModuleHeader } from './page-section.styles';

export type PageSectionProps = PropsWithChildren<{
  afterHeadline?: ReactNode;
  dataQa?: string;
  headline?: string | ReactNode;
  headlineDataQa?: string;
  headlineTranslationKey?: string;
  scrollTrackingId?: string;
}>;

export const PageSection: FC<PageSectionProps> = ({
  children,
  headline,
  headlineTranslationKey,
  afterHeadline,
  dataQa,
  headlineDataQa,
}) => {
  const renderPageSection = (
    headlineContent?: ReactNode
  ): React.JSX.Element => (
    <Container data-qa={dataQa}>
      {headlineContent && (
        <Section noBlockPadding>
          <LayoutPrimitivesContainter>
            <ModuleHeader>
              <SectionHeadline dataQa={headlineDataQa}>
                {headlineContent}
              </SectionHeadline>
              {afterHeadline && <AfterHeadline>{afterHeadline}</AfterHeadline>}
            </ModuleHeader>
          </LayoutPrimitivesContainter>
        </Section>
      )}
      {children}
    </Container>
  );

  return headlineTranslationKey ? (
    <FormattedMessage id={headlineTranslationKey}>
      {renderPageSection}
    </FormattedMessage>
  ) : (
    renderPageSection(headline)
  );
};
