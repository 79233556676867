import { useIntersectionObserver } from '@researchgate/react-intersection-observer';
import { useState } from 'react';
import type { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentBanner } from '@xing-com/banner';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import type { FallbackImageType } from '@xing-com/super-ellipse';
import { Headline } from '@xing-com/typography';

import { useCtaLinkOnClick } from '../components/cta-link';
import type { XingIdNonError } from '../xing-id';

import {
  StyledChildren,
  StyledContainer,
  StyledContent,
  StyledCtaButton,
  StyledImage,
  StyledSubHeadline,
  StyledTextContainer,
} from './sticky-button-layer.styles';

type StickyButtonLayerProps = {
  xingId: XingIdNonError;
  scrambledProfileUrl: string | null;
};

export const StickyButtonLayer: FunctionComponent<StickyButtonLayerProps> = ({
  xingId,
  scrambledProfileUrl,
}) => {
  const [showLayer, setShowLayer] = useState(false);

  const { isRecurringUser } = useRuntimeConfig();
  const { locale } = useIntl();

  const mainCtaText =
    isRecurringUser && locale === 'de'
      ? 'PROFILE_LO_XING_ID_MAIN_CTA_BUTTON_RECURRING_USER'
      : 'PROFILE_LO_XING_ID_MAIN_CTA_BUTTON';

  const [ref] = useIntersectionObserver(
    ({ isIntersecting }) => {
      setShowLayer(!isIntersecting);
    },
    { threshold: 0.5 }
  );

  const onCtaClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: 'sticky_bar_click',
  });

  if (!xingId) {
    return null;
  }

  const displayName = xingId.displayName;
  const profileImage = xingId.profileImage?.[0]?.url;
  const gender = (xingId?.gender ?? 'neutral').toLocaleLowerCase();
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const imageType = `${gender}-user` as FallbackImageType;

  return (
    <>
      <div ref={ref} />
      <ContentBanner show={showLayer} data-qa="sticky-button-layer">
        <StyledChildren>
          <StyledContainer>
            <StyledContent>
              <StyledImage
                src={profileImage}
                type={imageType}
                profileName={displayName}
                data-qa="sticky-layer-user-image"
              />
              <StyledTextContainer>
                <Headline
                  size="large"
                  sizeConfined="xxlarge"
                  data-qa="sticky-layer-headline"
                  noMargin
                >
                  <FormattedMessage
                    id="PROFILE_LO_FE_STICKY_LAYER_HEADLINE"
                    values={{ displayName }}
                  />
                </Headline>
                <StyledSubHeadline
                  size="small"
                  data-qa="sticky-layer-subheadline"
                  noMargin
                >
                  <FormattedMessage id="PROFILE_LO_FE_STICKY_LAYER_SUB_HEADLINE" />
                </StyledSubHeadline>
              </StyledTextContainer>
            </StyledContent>
            <StyledCtaButton
              onClick={onCtaClick}
              variant="primary"
              size="small"
              sizeConfined="medium"
            >
              <FormattedMessage id={mainCtaText} />
            </StyledCtaButton>
          </StyledContainer>
        </StyledChildren>
      </ContentBanner>
    </>
  );
};
