import { useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Section } from '@xing-com/layout-primitives';

import { PageSection } from '../components/page-section';

import {
  StyledButton,
  StyledBodyCopy,
  StyledIcon,
  Content,
} from './legal-notice.styles';

type LegalNoticeProps = {
  displayName: string;
  legalInformation: string;
};

export const LegalNotice: FC<LegalNoticeProps> = ({
  displayName,
  legalInformation,
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = (): void => {
    setShow(!show);
  };

  if (!displayName) {
    return null;
  }

  return (
    <PageSection
      headlineTranslationKey="PROFILE_LO_FE_LEGAL_NOTICE_HEADLINE"
      dataQa="legal-notice-section"
    >
      <Section noBlockPadding>
        <Container>
          <StyledButton onClick={toggleShow}>
            <StyledBodyCopy
              size="small"
              sizeConfined="medium"
              fontWeight="bold"
            >
              <FormattedMessage
                id="PROFILE_LO_FE_LEGAL_NOTICE_SUB_HEADLINE"
                values={{ displayName }}
              />
            </StyledBodyCopy>
            <StyledIcon width={24} height={24} $isShow={show} />
          </StyledButton>
          {show && (
            <Content
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: legalInformation }}
            />
          )}
        </Container>
      </Section>
    </PageSection>
  );
};
