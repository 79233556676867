import isNaN from 'lodash/isNaN';
import some from 'lodash/some';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Container } from '@xing-com/layout-primitives';
import { H } from '@xing-com/typography';

import { formatString } from '../../helpers';
import { BottomDescription } from '../bottom-description';
import { EntryGrid } from '../entry-grid';
import { Logo } from '../logo';
import type { ITimelineEntryProps as IProps } from '../types';

import {
  CurrentSign,
  MarkCurrent,
  Occupation,
  StyledBodyCopy,
  StyledHyperlink,
  StyledLi,
  Time,
} from './entry.styles';

const getFormattedDate = (date?: string | null): string | null => {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);

  if (isNaN(dateObj.getFullYear())) {
    return null;
  }

  return dateObj.toLocaleString(undefined, { month: 'short', year: 'numeric' });
};

type OrganisationNameProps = {
  children: ReactNode;
  href?: string | null;
};

const OrganisationName: FC<OrganisationNameProps> = ({ children, href }) =>
  href ? (
    <StyledHyperlink href={href}>{children}</StyledHyperlink>
  ) : (
    <StyledBodyCopy size="small" noMargin>
      {children}
    </StyledBodyCopy>
  );

export const TimelineEntry: FC<IProps> = (props) => {
  const {
    isCurrent,
    organisationName,
    occupation,
    beginDate,
    endDate,
    logo,
    url,
    description,
    localizedTimeString,
    entryDataQa,
  } = props;

  if (
    !some({
      isCurrent,
      organisationName,
      occupation,
      beginDate,
      endDate,
      url,
      logo,
      description,
    })
  ) {
    return null;
  }

  const beginDateFormatted = getFormattedDate(beginDate);
  const endDateFormatted = getFormattedDate(endDate);
  const showTimeSection =
    beginDateFormatted || isCurrent || localizedTimeString;

  return (
    <Container>
      <StyledLi data-qa={entryDataQa}>
        {isCurrent && <MarkCurrent />}
        <EntryGrid
          aside={<Logo src={logo ?? ''} href={url ?? ''} hideOnHandheld />}
          bottom={
            description && <BottomDescription description={description} />
          }
        >
          {showTimeSection && (
            <Time>
              {localizedTimeString && formatString(localizedTimeString)}
              {!localizedTimeString && (
                <>
                  {isCurrent && (
                    <CurrentSign>
                      <FormattedMessage id="PROFILE_LO_FE_TIMELINE_CURRENT" />
                    </CurrentSign>
                  )}
                  {beginDateFormatted}
                  {endDateFormatted && ` - ${endDateFormatted}`}
                </>
              )}
            </Time>
          )}
          <Logo src={logo ?? ''} href={url ?? ''} hideOnTabletAndDesktop />
          {occupation && (
            <Occupation>
              <H sizeWide="xlarge" size="xlarge" as="h4">
                {occupation}
              </H>
            </Occupation>
          )}
          {organisationName && (
            <OrganisationName href={url}>{organisationName}</OrganisationName>
          )}
        </EntryGrid>
      </StyledLi>
    </Container>
  );
};
